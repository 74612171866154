import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
import {
    Notification
} from 'element-ui';
import router from '../router/index'

// 设置请求超时
axios.defaults.timeout = 10000;

// post请求头的设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// //请求拦截
// import store from '../store/index';
// // 请求拦截器
// axios.interceptors.request.use(
//     config => {
//         // 每次发送请求之前判断vuex中是否存在token        
//         // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
//         // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
//         const token = sessionStorage.getItem('token');
//         token && (config.headers.Authorization = token);
//         return config;
//     },
//     error => {
//         return Promise.error(error);
//     })

    //  var baseUrl = 'http://testqdadminint.shenguang.co'
     var baseUrl = 'http://qdadminint.shilidangan.com'
        // var baseUrl = 'http://192.168.1.45:86'


  export function getBaseUrl() {
    return baseUrl
} 


// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为1 或 200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        if (response.status === 1 || response.status === 200) {
            if (response.data.status === 90050) {
                if (document.getElementsByClassName('el-notification').length === 0) {
                    Notification.error({
                        title: 'error',
                        message: response.data.msg,
                        showClose: false
                    })
                }
                // 清除token
                sessionStorage.removeItem('token');
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                setTimeout(() => {
                    router.push({
                        path: '/login'
                    });
                }, 1000);
            } else if (response.data.status === 90040) {
                if (document.getElementsByClassName('el-notification').length === 0) {
                    Notification.error({
                        title: 'error',
                        message: response.data.msg,
                        showClose: false
                    })
                }
                // 清除token
                sessionStorage.removeItem('token');
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                setTimeout(() => {
                    router.push({
                        path: '/login'
                    });
                }, 1000);
            } else {
                return Promise.resolve(response);
            }
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 90040: token错误
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 90040:
                    Notification.error({
                        title: 'error',
                        message: 'token无效,请重新登录',
                        showClose: true
                    });
                    router.replace({
                        path: '/login',
                    });
                    break;

                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面                
                case 90050:
                    Notification.error({
                        title: 'error',
                        message: '登录过期，请重新登录',
                        duration: 1000,
                        showClose: true
                    });
                    // 清除token
                    localStorage.removeItem('token');
                    // store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                    setTimeout(() => {
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
                    break;

                    // 404请求不存在
                case 404:
                    Notification.error({
                        title: 'error',
                        message: '网络请求不存在',
                        showClose: false
                    });
                    break;
                    // 其他错误，直接抛出错误提示
                default:
                    Toast({
                        message: error.response.data.message,
                        duration: 1500,
                        forbidClick: true
                    });
            }
            return Promise.reject(error.response);
        }
    })

// get方法
export function get(url, params) {
    let token = sessionStorage.getItem('token')
    return new Promise((resolve, reject) => {
        axios({
            url: baseUrl + url,
            method: 'GET',
            params: params,
            headers: {
                'JMWYW-TOKEN': token
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}
// editpost方法
export function editpost(url, data, route) {
    let token = sessionStorage.getItem('token')
    return new Promise((resolve, reject) => {
        axios({
                url: baseUrl + url,
                method: 'POST',
                data: data,
                transformRequest: [function (data) {

                    let ret = ''
                    for (let it in data) {
                        ret += encodeURIComponent(route + '.' + it) + '=' + encodeURIComponent(data[it]) + '&'
                    }

                    return ret
                }],
                headers: {
                    'JMWYW-TOKEN': token
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
// post方法
export function post(url, data) {
    let token = sessionStorage.getItem('token')
    return new Promise((resolve, reject) => {
        axios({
                url: baseUrl + url,
                method: 'POST',
                data: QS.stringify(data),
                headers: {
                    'JMWYW-TOKEN': token
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

// 上传json格式
export function postJson(url, data) {
    let token = sessionStorage.getItem('token')
    return new Promise((resolve, reject) => {
        axios({
                url: baseUrl + url,
                method: 'POST',
                data: data,
                headers: {
                    'JMWYW-TOKEN': token,
                    'Content-Type': 'multipart/json'
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
// 上传文件
export function postfile(url, data) {
    let token = sessionStorage.getItem('token')
    return new Promise((resolve, reject) => {
        axios({
                url: baseUrl + url,
                method: 'POST',
                data: data,
                headers: {
                    'JMWYW-TOKEN': token,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
